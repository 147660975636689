/** @format */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

*,
::before,
::after {
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  font-size: 1rem;
  -webkit-text-size-adjust: 100%;
  font-family: "Poppins", sans-serif !important;
}

:root {
  --bd-white: #e0e0e0;
  --input-bg: #d4d4d4;
  --terms-color: #db574a;
}

body {
  font-weight: 300;
  line-height: 1.65;
  min-height: 100vh;
  margin: 0;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: antialiased;
}

.phone-img {
  width: 17px;
  height: 17px;
}

.profile-img {
  width: 20px;
  height: 20px;
}

.dl-flex {
  display: flex;
}

.bg-white {
  background-color: #fff;
}

.header-top .navbar-light .navbar-nav .nav-link {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  padding: 17px 0;
  color: #254a74;
}

.main-logo {
  width: 200px;
  -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  /* image-rendering: pixelated; */
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.icon-img {
  width: 20px;
  height: 20px;
}

.navbar-toggler {
  padding: 0 !important;
}

.serach-icon-img {
  width: 17px;
  height: 17px;
}

.container {
  width: 1400px !important;
  max-width: 100% !important;
  padding: 0 30px !important;
  margin: 0 auto;
}

.navbar-collapse {
  z-index: 1;
}

button.backtotopbtn {
  background-color: unset;
  border: unset;
  position: absolute;
  left: 120px;
}

.social-icon {
  position: relative;
}

button.backtotopbtn img {
  width: 25px;
}

.tabs-btns button,
.nav-item button {
  background-color: #254a7466 !important;
  color: #fff !important;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.36px;
  width: 100px;
  height: 46px;
  border-radius: 8px !important;
  text-align: center;
  padding: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: unset !important;
  outline: unset;
}

.nav-link.active {
  background-color: #254a74 !important;
}

.nav-item button:focus-visible {
  outline: unset !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: unset !important;
  isolation: unset !important;
  background-color: #254a74 !important;
}

.nav-item:focus-visible {
  outline: unset !important;
  border: unset !important;
}

ul.nav.nav-tabs {
  display: flex;
  justify-content: space-between;
  border: unset;
}

.banner-bg {
  background-image: url(/src/assest/images/banner-bg.jpg);
  padding: 30px 0 50px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
}

.planning-box {
  width: 81%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0px 10px 10px 0px;
  margin: 30px auto;
  padding: 30px 20px;
}

.planning-box h1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.36px;
  color: #254a74;
  margin: 0 0 20px 0;
}

.planning-box p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.36px;
  color: #254a74;
  margin: 0;
}

.search-box {
  background: rgba(211, 211, 211, 0.7);
  border-radius: 8px;
  width: 97%;
  margin: 0 auto;
  padding: 0px 18px 10px;
}

.search-box input {
  margin: 6px 0 8px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  /* identical to box height, or 83% */
  text-align: left;
  letter-spacing: 0.36px;
  color: #2f2f2f;
}

.search-box span {
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  /* or 83% */
  text-align: center;
  letter-spacing: 0.36px;
  color: #254a74;
}

.search-box button {
  background: #7da244;
  border-radius: 4px;
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height, or 100% */
  text-align: center;
  letter-spacing: 0.36px;
  color: #ffffff;
  width: 122px;
  margin: 0 auto;
  display: block;
  padding: 8px 6px;
}

.search-box button img {
  width: 12px;
  z-index: 999999999;
  height: 12px;
}

.search-box input::placeholder {
  color: #2f2f2f;
  opacity: 1;
}

.location-button {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-align: center;
  letter-spacing: 0.36px;
  color: #254a74 !important;
  padding: 9px 0 0 0 !important;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}

.navbar-nav a:hover {
  color: #7da244 !important;
}

.search-box button:hover {
  background-color: #254a74;
  color: #fff !important;
}

.search-box button:focus {
  box-shadow: unset !important;
}

.tabs-btns button:hover {
  background-color: #254a74;
  box-shadow: unset;
}

.navbar-light .navbar-toggler {
  color: #333;
  border: unset !important;
}

.navbar-toggler {
  background-color: #fff !important;
}

.navbar-nav {
  flex-direction: row !important;
}

.navbar-collapse {
  height: 100vh;
  position: absolute;
  width: 100%;
  right: 0;
  text-align: right;
  background-color: #fff;
  padding: 20px 0 0 0;
  display: block;
  z-index: 999;
  animation: mymove 1s;
}

.menucontainer {
  position: absolute;
  width: 100%;
  right: 0;
  text-align: right;
  background-color: #fff;
  padding: 20px 0 0 0;
  display: block;
  z-index: 9999;
  transform: translateY(-110%);
  transition: all 1s;
}

.menucontainer.show {
  transform: translateY(10px);
  transition: all 1s;
}

.navbar-box button {
  background-color: unset !important;
  padding: 0;

  border: unset;
}

/* .collapsing {
  height: 100vh !important;
  animation: mymove2 2s;
} */
.navbar-nav img {
  margin: 0 10px;
}

.navbar-nav {
  justify-content: center;
}

.portfolio-filter.style1.portfolio-new {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

.portfolio-filter li.filter__item.active {
  background-color: #ebf8f9;
  color: #494949;
}

.portfolio-one__experience .portfolio-one__fimlor {
  background-color: #fc653c;
  padding: 0 30px;
  border-top-right-radius: 10px;
}

.portfolio-one__experience .portfolio-one__fimlor p a:hover {

  color: #fff;
}

.port_Text {
  padding-top: 50px;
  width: 65%;
  margin: 0 auto;
  text-align: center;
}

.port_Text h1 {
  color: #303031;
  margin: 0 auto;
  text-transform: uppercase;
}

.port_Text p {
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  margin: 2rem 0 0 0;
}

/* PORTFOLIO */
.portfolio-filter.style1.portfolio-new.custom-tab {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 4%;
}


.portfolio-filter li .main-cat {
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;

}

.portfolio-filter li.active .main-cat {
  background-color: #db574a;
  color: #fff;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.portfolio-filter li:hover .main-cat {
  background-color: #db574a;
  color: #fff;
}

.portfolio-filter li div.sub-cat {
  display: none;
  background-color: #d7d7d770;
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
  color: #202024;
}

.portfolio-filter li div.sub-cat span {
  padding: 10px 10px;
  margin-left: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.portfolio-filter li div.sub-cat span:hover {
  background-color: #e3e3e3;
}

.portfolio-filter li:hover .sub-cat {
  position: absolute;
  display: block;
  width: max-content;
}

.loading {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(149, 161, 127, .43137254901960786);
  z-index: 10000000000000000;
}

.loading img {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pt-8 {
  padding-top: 5rem !important;
}

/* .container.about-us {
  margin-top: 80px;
} */