.bgImg {
  background-image: url(../../../../assest/images/resources/footer-bg.jpg);
  background-size: cover;
  padding: 5rem;
  margin: 4rem 0 0 0;
}

.form1 {
  border: 1px solid black;
  background-color: black;
  color: #ffffff;
  width: 50%;
  padding: 5rem;
  border-radius: 17px;
  margin: 0 0 0 auto;
  opacity: 0.8;
}

.formText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.form1 h2 {
  font-size: 32px;
  color: #39b4bd;
  font-weight: 500;
  width: 65%;
}

.form1 p {
  line-height: 24px;
  font-size: 15px;
  margin: 1.5rem 0 0 0;
}

form {
  margin: 2rem 0 0 0;
}

.formBtn {
  padding: 1.5rem;
  background-color: #39b4bd;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  margin: 3rem 0 0 0;
  border: none;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #d2d2d2;
  outline: 0;
  font-size: 16px;
  color: #212121;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 16px;
  cursor: text;
  top: 20px;
  left: 0;
}

label,
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 12px;
  color: #9b9b9b;
}

.form__field:focus ~ .form__label {
  color: #009788;
}

.form__field:focus {
  padding-bottom: 6px;
  border-bottom: 2px solid #009788;
}
/* ............. media query ................... */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .form1 {
    width: 74%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .form1 {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 768px) and (min-width: 375px) {
  .form1 {
    width: 100%;
    padding: 3rem;
  }

  .form h2 {
    font-size: 20px;
  }

  .form p {
    line-height: 15px;
    font-size: 12px;
  }
  .formBtn {
    padding: 1rem;
    font-size: 12px;
    width: 100%;
  }
  .form1 h2 {
    font-size: 20px;
    width: unset;
  }
  .form1 p {
    line-height: 17px;
    font-size: 12px;
  }
}
