.knightPic {
  width: 550px;
  margin-top: -100px;
  margin-left: -90px;
}

.stand {
  margin: 3rem 0 0 0;
}
.stand h1 {
  width: 40%;
  color: #303031;
}
.standText_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}

.standText_3 h3 {
  font-size: 20px;
  line-height: 28px;
}

.standText_3 {
  margin: 3rem 0 0 0;
}

.stand p {
  font-size: 15px;
  line-height: 26px;
  padding: 15px 0;
}

/* ....................... media query ........................ */
@media screen and (max-width: 1200px) {
  .knightPic {
    display: none;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1024px) {
  .knightPic {
    display: none;
  }
  .stand .col-8 {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .stand {
    margin: 1rem 0 0 0;
  }
  .stand h1 {
    font-size: 48px !important;
  }
  .stand h1 {
    text-align: center;
    margin: 0 auto;
  }
  .stand .col-8 {
    width: 100%;
  }
  .stand h1 {
    font-size: 42px !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 375px) {
  .portfolio-page{
 
    padding: 108px 0 0px;
  }
  .stand h1 {
    font-size: 23px !important;
    width: 90%;
    margin: 0 auto;
  }
  .standText_2 .w-50 {
    width: 90%!important;
}
.bgImg{
  padding:0 40px;
}
  .standText_2 {
    flex-direction: column;
    text-align: center;
  }
  .standText_2 p {
    font-size: 14px;
  }
  .stand p {
    text-align: center;
  }
  .standText_1 .col-8 {
    width: unset;
  }
  .standText_2 .col-6 {
    width: unset;
  }
  .standText_3 h3 {
    font-size: 18px;
  }
}
